import { gql } from '@urql/core';

export const PROFESSIONALISM_TEST_QUERY = gql`
    query professionalismTest {
      professionalismTest {
        status
        message
        should_show_questions
        overall_status
        entities {
          type
          id
          name
          status
          all_questions_answered
          is_confirmed
          all_questions {
            id
            question
            answer
          }
        }
      }
    }
`;

export const PROFESSIONALISM_TEST_ANSWERED_QUESTIONS_QUERY = gql`
    query professionalismTest {
      entities {
        type
        name
        all_questions {
          question
          answer
        }
      }
    }
`;

export const SNIPPET_QUERY = gql`
    query snippet($key: String) {
      snippet(key: $key) {
        id
        name
        contents
      }
    }
`;

export const ONBOARDING_QUERY = gql`
  query onboarding($token: String!, $signing_id: ID!) {
    onboarding(token: $token, signing_id: $signing_id) {
      me {
        id
        name
      }
      direct_debit_authorization_url
      direct_debit_authorized_at
      status
      agreements {
        link
      }
      documentContext {
        documentOwners {
          owner {
            ... on Person {
              id
              name
            }
            ... on Company {
              id
              name
              coc_number
              __typename
            }
          }
          items {
            id
            key
            name
            type
            deletable_for_customer
            maxSize
            template {
              id
              filename
              extension
              absolute_url
            }
            records {
              asset {
                absolute_url
                extension
                filename
                id
                filesize {
                  raw
                  human
                }
              }
              downloadable_for_customer
              id
              name
            }
          }
        }
        title
      }
    }
  }
`;

export const QUERY_ONBOARDING_TEMPLATE = gql`
  query professionalismTestReconsideration {
    professionalismTestReconsideration {
      status
      message
      all_questions {
        id
        slug
        question
        answer
        question_type
      }
    }
  }
`;

export const QUERY_GC_ONBOARDING_TEMPLATE = gql`
  query onboardingTemplate($token: String!, $signing_id: ID!, $document_id: ID!) {
    onboardingTemplate(token: $token, signing_id: $signing_id, document_id: $document_id) {
      link
    }
  }
`;

export const SHOULD_SHOW_PROFESSIONALISM_TEST_QUERY = gql`
  query professionalismTest {
    professionalismTest {
      should_show_questions
      overall_status
      entities {
        type
        status
      }
    }
  }
`;
